* {
  box-sizing: border-box;
  font-family: "Varela Round", sans-serif;
  margin: 0;
  padding: 0;
  font-size: 1em;
}

html {
  font-size: 62.5%;
  background-size: cover;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: white;
  height: 100%;
}

.Toastify__toast-body {
  font-size: 1.4rem;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

.content {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.content > div {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
